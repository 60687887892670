/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  OrganizationAnalytics,
  OrganizationAnalyticsVariables,
  OrganizationAnalytics_organizationAnalytics_lots as Lots,
} from './types/OrganizationAnalytics'

export const ORGANIZATION_ANALYTICS = gql`
  query OrganizationAnalytics(
    $regionId: Int
    $subregionId: Int
    $commercialExecutiveId: Int
    $timeMode: AnalyticsTimeMode
    $status: [RiceLotStatus!]
    $dateRange: DateRangeInput
    $companyId: Int
    $cropId: Int!
    $varietyId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $grainType: GrainType
    $productionType: ProductionType
  ) {
    organizationAnalytics(
      regionId: $regionId
      subregionId: $subregionId
      commercialExecutiveId: $commercialExecutiveId
      timeMode: $timeMode
      status: $status
      dateRange: $dateRange
      companyId: $companyId
      cropId: $cropId
      varietyId: $varietyId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      grainType: $grainType
      productionType: $productionType
    ) {
      summary {
        lots {
          total
          filtered
        }
        size {
          filtered
          total
        }
        totalCompanies
        totalCommercialExecutives
      }
      lots {
        lot {
          id
          name
          commercialName
          area {
            coordinates
            type
          }
          location {
            coordinates
          }
          riceLot {
            emergenceDate
            yield
            estimatedYield
          }
          size
          variety {
            id
            name
            locales {
              es
            }
          }
          crop {
            id
            locales {
              es
            }
          }
          seasonField {
            field {
              id
              name
            }
            season {
              millDeliveryType
              commercialExecutive {
                id
                name
                lastName
              }
              company {
                name
                governmentId
              }
            }
          }
        }
        status {
          estimated {
            status
            date
            updateType
          }
          current {
            status
            date
            updateType
          }
          conversions {
            status
            date
          }
        }
      }
      harvestSummary {
        estimatedProduction
        declaredProduction
        deliveredProduction
        lots {
          harvested
          unharvested
          total
        }
        size {
          harvested
          unharvested
          total
        }
      }
    }
  }
`
