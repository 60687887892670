import { orderBy } from 'lodash'

import { NumberUtils } from 'utils'

import { OrganizationAnalyticsSummaryVariety } from './api'
import { useFetchOrganizationAnalyticsSummary } from './hooks'
import { DataObject } from '../../types'

export const useOrganizationAnalyticsSummary = () => {
  const {
    commercialExecutives,
    companies,
    varieties,
    totals,
    loading,
    totalLotsWithEmergenceDate,
    totalSizeWithEmergenceDate,
  } = useFetchOrganizationAnalyticsSummary()

  const lotsQty = totals?.lots ?? 0
  const haQty = totals?.size ? NumberUtils.roundedNumber(totals.size) : 0

  const handleChartData = (
    organizationAnalyticsSummaryVarieties?: OrganizationAnalyticsSummaryVariety[],
  ): DataObject[] => {
    if (!organizationAnalyticsSummaryVarieties) return []

    const varietiesList =
      varieties?.map(variety => {
        return {
          name: variety.variety?.locales.es ?? 'Sin variedad',
          lots: variety.totals.lots,
          size: NumberUtils.roundedNumber(variety.totals.size),
        }
      }) ?? []

    const orderedVarieties = orderBy(varietiesList, ['size'], ['desc'])
    return orderedVarieties
  }

  return {
    loading,
    varieties,
    commercialExecutives: commercialExecutives ?? [],
    companies: companies ?? [],
    lotsQty,
    haQty,
    varietiesChartData: handleChartData(varieties),
    emergenceDatesChartData: [
      { value: totalLotsWithEmergenceDate },
      { value: lotsQty - totalLotsWithEmergenceDate },
    ],
    emergenceSizeDatesChartData: [
      { value: totalSizeWithEmergenceDate },
      { value: haQty - totalSizeWithEmergenceDate },
    ],
  }
}
