import { Col, Row, Space } from 'antd'
import { useTheme } from 'styled-components'
import { useState } from 'react'
import { Dayjs } from 'dayjs'

import { ItemInfo } from 'features/MainRouter/components'
import { DateRangePicker, GrainTypeSelect, ProductionTypeSelect } from 'components'
import { useDashboard } from 'features/MainRouter/features/Dashboard/hooks'
import { NumberUtils } from 'utils'
import { GrainType, ProductionType, SelectValueType } from 'types'

import { Square, Chart, Button } from './components'

interface Props {
  data: {
    date: string
    lotValue?: number
    areaValue?: number
    futureAreaValue?: number
    futureLotValue?: number
  }[]
  lotsQty: number
  areaQty: number
}

type ViewActive = 'lots' | 'area'

export const HarvestStats: React.FC<Props> = ({ data, lotsQty, areaQty }) => {
  const { colors } = useTheme()
  const [viewActive, setViewActive] = useState<ViewActive>('lots')
  const isLotsViewActive = viewActive === 'lots'
  const isAreaViewActive = viewActive === 'area'

  const {
    setGrainType,
    setEndDate,
    setStartDate,
    setProductionType,
    endDate,
    startDate,
    grainType,
    productionType,
  } = useDashboard()

  const onChangeStartDate = (date: Dayjs | null) => {
    if (date?.isAfter(endDate)) setEndDate(date)
    setStartDate(date)
  }
  const onChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
  }

  const onChangeGrainType = (value: SelectValueType<GrainType>) => {
    setGrainType(value)
  }

  const onChangeProductionType = (value: SelectValueType<ProductionType>) => {
    setProductionType(value)
  }

  return (
    <Row gutter={30}>
      <Col xs={24} xl={6}>
        <Space size={60} direction="vertical">
          <Space direction="vertical" size={20}>
            <ProductionTypeSelect
              onChange={onChangeProductionType}
              value={productionType}
              width="243px"
            />
            <GrainTypeSelect onChange={onChangeGrainType} value={grainType} width="243px" />
            <DateRangePicker
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
              startDate={startDate}
              endDate={endDate}
            />
            <Space size={16}>
              <Button
                type={isLotsViewActive ? 'default' : 'primary'}
                onClick={() => setViewActive('lots')}
              >
                Lotes
              </Button>
              <Button
                type={isAreaViewActive ? 'default' : 'primary'}
                onClick={() => setViewActive('area')}
              >
                Hectáreas
              </Button>
            </Space>
          </Space>
          <Space size={55}>
            <ItemInfo
              Icon={<Square $background={colors.primary[500]} />}
              title="Lotes"
              data={isLotsViewActive ? NumberUtils.localize(lotsQty) : '-'}
              verticalSpacing={7}
              horizontalSpacing={16}
            />
            <ItemInfo
              Icon={<Square $background={colors.primary[500]} />}
              title="Hectáreas"
              data={isAreaViewActive ? NumberUtils.localize(areaQty) : '-'}
              verticalSpacing={7}
              horizontalSpacing={16}
            />
          </Space>
        </Space>
      </Col>
      <Col xs={24} xl={18} style={{ height: '215px' }}>
        <Chart data={data} showAreaData={isAreaViewActive} showLotsData={isLotsViewActive} />
      </Col>
    </Row>
  )
}
