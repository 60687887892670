import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { SelectProps } from 'antd/lib'

import { ProductionType, SelectValueType, Selects } from 'types'
import { RICE_LOT } from 'consts'

const { Option } = AntSelect

const Select = styled(AntSelect)<SelectProps & { width?: string }>`
  .ant-select-selector {
    width: ${({ width }) => width && width} !important;
  }
`

const productionTypes = [ProductionType.CONVENTIONAL, ProductionType.ORGANIC]

interface Props {
  onChange: (productionType: SelectValueType<ProductionType>) => void
  value?: SelectValueType<ProductionType>
  width?: string
}

export const ProductionTypeSelect: React.FC<Props> = ({ onChange, value, width }) => {
  const productionTypesOptions =
    productionTypes.map(productionType => ({
      label: RICE_LOT.PRODUCTION_TYPE_NAME_MAP[productionType],
      value: productionType,
    })) ?? []

  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      notFoundContent={'No hay coincidencia'}
      value={value ?? Selects.ALL_OPTIONS}
      onChange={onChange}
      width={width}
    >
      <Option value={Selects.ALL_OPTIONS}>Total</Option>
      {productionTypesOptions.map(productionType => (
        <Option key={productionType.value} value={productionType.value}>
          {productionType.label}
        </Option>
      ))}
    </Select>
  )
}
