import { Col, Row } from 'antd'
import styled from 'styled-components'

export { Chart } from './Chart'

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.baseColor[900]};
`

export const ChartsRow = styled(Row)`
  max-height: 500px;
  overflow-y: auto;
  padding: 10px 8px;
`

export const ChartContainer = styled(Col)`
  margin-top: 25px;
`
