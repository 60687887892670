import dayjs from 'dayjs'
import { compact, flatMap, groupBy, map, merge, sumBy } from 'lodash'

import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { DashboardFiltersContext } from 'features/MainRouter/contexts'
import { useOrganizationAnalyticsLots } from 'hooks'
import { RiceLotStatus } from 'types'
import { DATE_FORMATS, UNITS } from 'consts'
import { MiscUtils } from 'utils'

import { LotWithHarvestDate } from './types'

const TODAY = dayjs()

export const useDashboard = () => {
  const {
    commercialExecutive,
    subregion,
    setCommercialExecutive,
    setEndDate,
    setStartDate,
    endDate,
    startDate,
    grainType,
    setGrainType,
    setSubregion,
    company,
    setCompany,
    productionType,
    setProductionType,
  } = DashboardFiltersContext.useDashboardFiltersContext()
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedHistoricalSeason, setSelectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()
  const { setSelectedVarietyId, selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()

  const { data, loading } = useOrganizationAnalyticsLots({
    riceLotStatus: [RiceLotStatus.HARVEST],
    dateRange: {
      startDate: startDate!.format(DATE_FORMATS.REVERSE_DATE),
      endDate: endDate!.format(DATE_FORMATS.REVERSE_DATE),
    },
    commercialExecutiveId: commercialExecutive?.id,
    regionId: region?.id,
    subregionId: subregion?.id,
    companyId: company?.id,
    cropId: selectedCrop?.id,
    grainType: MiscUtils.formatSelectValue(grainType),
    productionType: MiscUtils.formatSelectValue(productionType),
    seasonStartRange: selectedHistoricalSeason?.startRange,
    seasonEndRange: selectedHistoricalSeason?.endRange,
    varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
  })

  const differenceBetweenDates = endDate?.diff(startDate, 'days')

  const rangeDates = Array(differenceBetweenDates! + 1)
    .fill((fill: unknown) => fill)
    .map((_, i) => {
      const harvestDate = endDate?.subtract(i, 'day').format(DATE_FORMATS.REVERSE_DATE)

      const isDateInData = data?.organizationAnalytics.lots?.some(lot => {
        const lotHarvestDate = lot.status?.conversions?.find(
          conversion => conversion.status === RiceLotStatus.HARVEST,
        )
        if (!lotHarvestDate) return false

        return dayjs(lotHarvestDate.date).format(DATE_FORMATS.REVERSE_DATE) === harvestDate
      })

      if (isDateInData) return null

      return { harvestDate }
    })

  const rangeDatesWithoutNull = compact(rangeDates)

  const filteredLots = data?.organizationAnalytics.lots?.map(lot => {
    const lotHarvestDate = lot.status?.conversions?.find(
      conversion => conversion.status === RiceLotStatus.HARVEST,
    )
    return { ...lot, harvestDate: dayjs(lotHarvestDate?.date).format(DATE_FORMATS.REVERSE_DATE) }
  })

  const rangeDatesWithLotsData = merge(rangeDatesWithoutNull, filteredLots)

  const harvestedLotsDataGropuedByHarvestedDateWeekYear = groupBy(rangeDatesWithLotsData, lot =>
    dayjs(lot?.harvestDate).weekYear(),
  )

  const harvestedLotsDataGroupedByHarvestedDateWeekYearValues = Object.values(
    harvestedLotsDataGropuedByHarvestedDateWeekYear,
  )

  const harvestedLotsDataGroupedByHarvestedDateWeek =
    harvestedLotsDataGroupedByHarvestedDateWeekYearValues.map(lot =>
      groupBy(lot, lotValue => dayjs(lotValue.harvestDate).week()),
    )

  const orderLots = flatMap(harvestedLotsDataGroupedByHarvestedDateWeek, arr => map(arr))

  const getChartData = (lots: LotWithHarvestDate[]) => {
    const realHarvestedLots = lots.filter(
      filteredLot =>
        filteredLot.lot?.name && filteredLot.status.current.status === RiceLotStatus.HARVEST,
    )
    const estimatedHarvestedLots = lots.filter(
      filteredLot =>
        filteredLot.lot?.name && filteredLot.status.current.status !== RiceLotStatus.HARVEST,
    )

    return {
      lotValue: realHarvestedLots.length ?? 0,
      areaValue: sumBy(realHarvestedLots, 'lot.size') ?? 0,
      futureLotValue: estimatedHarvestedLots.length ?? 0,
      futureAreaValue: sumBy(estimatedHarvestedLots, 'lot.size') ?? 0,
    }
  }

  const harvestedDataChart = map(orderLots, (lots, index) => {
    const isHarvestDateInActualWeek = dayjs(lots[0].harvestDate).week() === TODAY.week()
    const { lotValue, areaValue, futureAreaValue, futureLotValue } = getChartData(lots)

    return {
      date: isHarvestDateInActualWeek ? `Semana ${index + 1} (actual)` : `Semana ${index + 1}`,
      lotValue,
      futureAreaValue,
      futureLotValue,
      areaValue,
      days: `${dayjs(lots[0].harvestDate).weekday(0).format(DATE_FORMATS.WEEK_DAY)} a ${dayjs(
        lots[0].harvestDate,
      )
        .weekday(6)
        .format(DATE_FORMATS.WEEK_DAY)}`,
    }
  })

  const estimatedProduction =
    Number(data?.organizationAnalytics.harvestSummary?.estimatedProduction.toFixed() ?? 0) *
    UNITS.KG_TONS_RATIO

  const harvestedProduction =
    Number(data?.organizationAnalytics.harvestSummary?.declaredProduction.toFixed() ?? 0) *
    UNITS.KG_TONS_RATIO

  const productionToMill =
    Number(data?.organizationAnalytics.harvestSummary?.deliveredProduction.toFixed() ?? 0) *
    UNITS.KG_TONS_RATIO

  const harvestedLotsQty = data?.organizationAnalytics.harvestSummary?.lots.total ?? 0

  const unharvestedLotsQty = data?.organizationAnalytics.harvestSummary?.lots.unharvested ?? 0

  const totalLotsHarvested = data?.organizationAnalytics.harvestSummary?.lots.harvested ?? 0

  const harvestedHaQty = Number(
    data?.organizationAnalytics.harvestSummary?.size.total.toFixed() ?? 0,
  )

  const unharvestedHaQty = Number(
    data?.organizationAnalytics.harvestSummary?.size.unharvested.toFixed() ?? 0,
  )

  const totalHaHarvested = Number(
    data?.organizationAnalytics.harvestSummary?.size.harvested.toFixed() ?? 0,
  )

  return {
    setCommercialExecutive,
    setEndDate,
    setSubregion,
    setStartDate,
    setCompany,
    setSelectedHistoricalSeason,
    setSelectedVarietyId,
    setGrainType,
    setProductionType,
    company,
    startDate,
    endDate,
    loading,
    estimatedProduction,
    harvestedProduction,
    productionToMill,
    harvestedLotsQty,
    unharvestedLotsQty,
    totalLotsHarvested,
    harvestedHaQty,
    unharvestedHaQty,
    totalHaHarvested,
    harvestedLotStatsChartData: harvestedDataChart,
    commercialExecutiveId: commercialExecutive?.id,
    selectedHistoricalSeason,
    region,
    subregion,
    selectedVarietyId,
    cropId: selectedCrop?.id,
    grainType,
    productionType,
  }
}
