import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'

import { NumberUtils } from 'utils'

import { DataObject } from '../../../../types'

interface Props {
  data: DataObject[]
  fill: string
  textColor?: string
  dataKey: string
}

export const Chart: React.FC<Props> = ({ data, fill, textColor, dataKey }) => {
  const height = data.length * 40
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data} layout="vertical" margin={{ left: 35 }}>
        <CartesianGrid strokeDasharray="0 0" horizontal={false} />
        <XAxis hide axisLine={false} type="number" />
        <YAxis orientation="left" dataKey={'name'} type="category" tickLine={false} />
        <Bar
          dataKey={dataKey}
          barSize={30}
          fill={fill}
          background={{ fill: 'transparent' }}
          isAnimationActive={false}
        >
          <LabelList
            formatter={(value: number) => NumberUtils.localize(value)}
            dataKey={dataKey}
            position="insideLeft"
            fill={textColor}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
